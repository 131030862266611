// actions.js

// export const speak = (message) => {
//   return async (dispatch) => {
//     return;
//     // Get all voices
//     const voices = window.speechSynthesis.getVoices();
//     console.log(voices);
//     // Filter for female voices
//     const femaleVoices = voices.filter(voice => voice.name.toLowerCase().includes('female'));
//     // Use the first female voice, or the default voice if there are no female voices
//     console.log(femaleVoices);
//     const voice = femaleVoices.length > 0 ? femaleVoices[0] : voices[0];
//     console.log(voice);

//     // Create a new SpeechSynthesisUtterance object
//     let utterance = new SpeechSynthesisUtterance(message);
//     // Set the voice
//     utterance.voice = voice;
//     // Set the language
//     utterance.lang = 'ro-RO';
//     // Speak the message
//     window.speechSynthesis.speak(utterance);
//   }
// }


export const sendMessage = (message, bot, aid) => {
  return async (dispatch) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/chat/thread/current', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'cbsession': `${localStorage.getItem('sessionId')}`,
        },
        body: JSON.stringify({ message, bot, aid })
      });

      if (response.ok) {
        const reader = response.body.getReader();
        let result;

        const sessionId = response.headers.get('cbsession');

        if (sessionId) {
          localStorage.setItem('sessionId', sessionId);
        }

        let decoder = new TextDecoder('utf-8');
        while ((result = await reader.read()) && !result.done) {
          // chunks.push(result.value);
          // let text = decoder.decode(new Uint8Array(chunks.flat()));
          let text = decoder.decode(new Uint8Array(result.value));
          dispatch({
            type: 'ADD_MESSAGE',
            payload: {
              source: 'bot-delta',
              data: text
            }
          });
        }
        dispatch({
          type: 'MESSAGE_DONE',
          payload: {
            source: 'bot',
            data: 'DONE',//data.message
            message: '',
          }
        });
      } else {
        throw new Error('Error sending message');
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// get messages
export const getMessages = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/chat/thread/current', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'cbsession': `${localStorage.getItem('sessionId')}`,
        }
      });

      if (response.ok) {
        const sessionId = response.headers.get('cbsession');

        if (sessionId) {
          localStorage.setItem('sessionId', sessionId);
        }

        const data = await response.json();

        const text = data.messages.map((message) => {
          return {
            source: message.role,
            data: message.content
          };
        });
        // speak(text);
        dispatch({
          type: 'GET_MESSAGES',
          payload: text

        });
      } else {
        throw new Error('Error getting messages');
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addMessage = (message, source = 'user') => {
  return {
    type: 'ADD_MESSAGE',
    payload: {
      source: source,
      data: message
    }
  };
};

export const clearMessages = () => {
  return async (dispatch) => {
    try {
      await fetch(process.env.REACT_APP_API_URL + '/chat/thread/new', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      await dispatch({
        type: 'SET_MESSAGES', // CLEAR_MESSAGES
        payload: {
          // source: 'bot',
          // data: process.env.REACT_APP_INITIAL_MESSAGE
        }
      });

      // speak(process.env.REACT_APP_INITIAL_MESSAGE);
    } catch (error) {
      console.log(error);
    }
  }
};