// reducer.js
const initialState = {
  messages: []
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      if (action.payload.source === 'bot-delta') {
        // add the delta message to the last message, only if the last message is from the bot, otherwise add a new message
        const lastMessage = state.messages.pop();
        if (lastMessage.source === 'bot') {
          lastMessage.data += action.payload.data;
          return {
            ...state,
            messages: [...state.messages, lastMessage]
          };
        } else {
          action.payload.source = 'bot';
          return {
            ...state,
            messages: [...state.messages, lastMessage, action.payload]
          };

        }
      }
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    case 'SEND_MESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    case 'GET_MESSAGES':
      return {
        ...state,
        messages: action.payload
      };
    case 'CLEAR_MESSAGES':
      return {
        ...state,
        messages: []
      };
    case 'SET_MESSAGES':
      return {
        ...state,
        messages: [action.payload]
      };
    default:
      return state;
  }
};