// ChatSupport.js
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMessage, sendMessage, getMessages, clearMessages } from "./actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faMicrophoneSlash,
  faPaperPlane,
  faBroom,
  faCommentDots,
  faCompress,
  faExpand,
  faRectangleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { RotatingLines as Loader } from "react-loader-spinner";
import "./ChatSupport.scss";
import { useTypewriter } from "react-simple-typewriter";

import { useLocation } from "react-router-dom";
/**
 * Component for displaying a chat support interface.
 *
 * @component
 * @example
 * return (
 *   <ChatSupport />
 * )
 */
function ChatSupport() {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages); // Access messages from Redux store
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(); // Create a reference to the input
  const messagesEndRef = useRef(null);
  const [isBotReplyPending, setIsBotReplyPending] = useState(false);
  const isBotReplyPendingRef = useRef(isBotReplyPending);
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [introText] = useTypewriter({
    words: ["Bun venit!", "Cum va pot ajuta?"],
    loop: 1,
    cursor: true,
    cursorStyle: "|",
    typeSpeed: 100,
    deleteSpeed: 100,
    delaySpeed: 1000,
    onLoopDone: async () => {
      await sleep(5000);
      setIsFirstTime(false);
    },
  });

  const bot = queryParams.get("bot");
  const aid = queryParams.get("aid");

  // Update isBotReplyPendingRef when isBotReplyPending changes
  useEffect(() => {
    isBotReplyPendingRef.current = isBotReplyPending;
  }, [isBotReplyPending]);

  // Load messages from api on first render
  useEffect(() => {
    // setIsFirstTime(false);
    // detect if speech recognition is available
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      recognitionRef.current = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();
      recognitionRef.current.interimResults = true;
      recognitionRef.current.continuous = true; // Keep listening even if the user pauses
      recognitionRef.current.lang = "ro-RO"; // Set language to Romanian

      // Add event listeners
      recognitionRef.current.onaudiostart = () => {
        setIsListening(true);
      };

      recognitionRef.current.onresult = (event) => {
        if (isBotReplyPendingRef.current) return;
        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            const transcript = event.results[i][0].transcript;
            handleSendMessage(transcript);
          }
        }
      };

      recognitionRef.current.onspeechend = () => {
        setIsListening(false);
      };
    }

    // Load messages from api
    setIsLoading(true);

    fetchMessages();

    // Add initial message
    // dispatch(addMessage(process.env.REACT_APP_INITIAL_MESSAGE, "bot"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!messages.length && isOpen) {
      // dispatch(addMessage(process.env.REACT_APP_INITIAL_MESSAGE, "bot"));
    } else {
      if (messages.length && messages[messages.length - 1].source === "bot") {
        setIsBotReplyPending(false);
      }
      scrollToBottom();
    }
    // eslint-disable-next-line
  }, [messages /*, isBotReplyPending*/, isOpen, isResetOpen]);

  const fetchMessages = async () => {
    await dispatch(getMessages());
    setIsLoading(false);
  };

  const handleSpeak = () => {
    if (recognitionRef.current) {
      if (!isListening) {
        recognitionRef.current.start();
      } else {
        recognitionRef.current.stop();
      }
    }
  };

  const handleNewChat = async () => {
    await dispatch(clearMessages());
    setIsResetOpen(!isResetOpen);
    localStorage.removeItem("sessionId");
    fetchMessages();
  };

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const scrollToBottom = async () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
    }
  };

  const handleSendMessage = async (message = null) => {
    try {
      setNewMessage(message);
      if (message && message.trim() !== "") {
        setIsBotReplyPending(true);
        setNewMessage("");
        await dispatch(addMessage(message));
        await dispatch(sendMessage(message, bot, aid));
        setIsBotReplyPending(false);
      }
      if (inputRef.current) inputRef.current.focus(); // Give back focus to the input
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage(inputRef.current.value);
      event.preventDefault(); // Prevents form submission
    }
  };

  useEffect(() => {
    console.log("isFirstTime", isFirstTime);
  }, [isFirstTime]);

  return (
    <div className={`ChatSupport ${isMaximized ? "maximized" : ""}`}>
      {!isOpen && (
        <>
          {isFirstTime && (
            <div className="hint-block hide-show-hint">{introText}</div>
          )}
          <button className="OpenButton" onClick={() => setIsOpen(true)}>
            <FontAwesomeIcon color="#aab4ff" icon={faCommentDots} />
          </button>
        </>
      )}

      {isOpen && (
        <div className="chat-popup">
          <div>
            <div
              className="header"
              onDoubleClick={() => setIsMaximized(!isMaximized)}
            >
              <button className="CloseButton" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faRectangleXmark} />
              </button>
              <p className="title">Chat Support</p>
              <button
                className="MaximizeButton"
                onClick={() => setIsMaximized(!isMaximized)}
              >
                <FontAwesomeIcon icon={isMaximized ? faCompress : faExpand} />
              </button>
            </div>
          </div>

          <div className="body">
            {isLoading ? (
              <div className="LoadingSpinner">Loading...</div>
            ) : (
              <>
                {messages.map((message, index) => (
                  <p className={message.source} key={index}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: message.data?.replaceAll("\n", "<br/>"),
                      }}
                    />
                  </p>
                ))}
                <div className={isResetOpen ? "reset-popup" : "hide"}>
                  <p className="reset-message">
                    Doriti sa incepeti o noua conversatie?
                  </p>
                  <div className="reset-popup-action-buttons">
                    <button className="confirm-button" onClick={handleNewChat}>
                      Confirma
                    </button>
                    <button
                      className="cancel-button"
                      onClick={() => setIsResetOpen(!isResetOpen)}
                    >
                      Anuleaza
                    </button>
                  </div>
                </div>
              </>
            )}

            {isBotReplyPending && (
              <div className="bot">
                <Loader height="40" width="40" ariaLabel="loading" />
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <div className="footer">
            <input
              ref={inputRef} // Attach the reference to the input
              value={newMessage}
              onChange={handleNewMessageChange}
              onKeyDown={handleKeyPress}
              placeholder="Mesajul tau..."
              className="NewMessageInput"
            />
            <button onClick={() => handleSendMessage(inputRef.current.value)}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
            <button onClick={handleSpeak}>
              <FontAwesomeIcon
                icon={isListening ? faMicrophone : faMicrophoneSlash}
              />
            </button>
            <button
              onClick={() => {
                setIsResetOpen(!isResetOpen);
                scrollToBottom();
              }}
            >
              <FontAwesomeIcon icon={faBroom} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatSupport;
