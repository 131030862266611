import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import './index.css';
import { thunk } from 'redux-thunk';
import { chatReducer } from './reducers'; // import your root reducer
import App from './App';

require('dotenv').config()

const store = createStore(chatReducer, applyMiddleware(thunk));

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
  root.render(<Provider store={store}>
    <App />
  </Provider>);
}

function renderChatbot(element) {
  if (element) {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      element);
  } else {
    console.error(`No element with id "${element}" found`);
  }
}

export default renderChatbot;